import Story from '@/models/Story'
import { Image } from '@/models/Image'
import NewsDetail from '@/modals/NewsDetail.vue'

export default class News extends Story {
  public get image(): Image {
    return this.content?.image.id ? this.content.image : null
  }

  public get thumb(): string {
    return this.image ? `${this.image.filename}/m/300x300/smart` : ''
  }

  public get picture(): string {
    return this.image ? `${this.image.filename}/m/1200x1200/smart` : ''
  }

  public get perex() {
    return this.content.perex
  }

  public get blocks() {
    return this.content.content
  }

  public get view() {
    return NewsDetail
  }
}
