<template>
  <transition
    :css="false"
    name="expand"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <div v-if="value !== false">
      <slot />
    </div>
  </transition>
</template>

<script>
import { gsap } from 'gsap'

export default {
  props: {
    duration: {
      type: Number,
      default: 0.5,
    },
    value: {
      type: Boolean,
      default: null,
    },
  },
  setup(props) {
    const beforeEnter = (el) => {
      el.style.opacity = 0
      el.style.height = 0
      el.style.overflow = 'hidden'
    }

    const afterEnter = (el) => {
      el.style.opacity = ''
      el.style.height = ''
      el.style.overflow = ''
    }

    const enter = (el, onComplete) => {
      el.style.height = 'auto'
      const height = el.clientHeight
      el.style.height = 0

      gsap.to(el, {
        opacity: 1,
        height,
        duration: props.duration,
        ease: 'power2.out',
        onComplete,
      })
    }

    const leave = (el, onComplete) => {
      gsap.to(el, {
        opacity: 0,
        height: 0,
        duration: props.duration,
        ease: 'power2.out',
        onComplete,
      })
    }

    const beforeLeave = (el) => {
      el.style.overflow = 'hidden'
    }

    const afterLeave = (el) => {
      el.style.overflow = ''
    }

    return {
      beforeEnter,
      afterEnter,
      enter,
      leave,
      beforeLeave,
      afterLeave,
    }
  },
}
</script>
