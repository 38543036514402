import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bb762b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-transition" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      mode: "out-in",
      onBeforeLeave: _ctx.onBeforeLeave,
      onLeave: _ctx.onLeave,
      onEnter: _ctx.onEnter,
      onBeforeEnter: _ctx.onBeforeEnter,
      onAfterEnter: _ctx.onAfterEnter
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["onBeforeLeave", "onLeave", "onEnter", "onBeforeEnter", "onAfterEnter"])
  ]))
}