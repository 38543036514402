
import { gsap } from 'gsap'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const onLeave = (el: HTMLElement, onComplete: () => void) => {
      gsap.set(el.parentElement, {
        height: el.clientHeight,
      })

      gsap.to(el, {
        xPercent: -15,
        opacity: 0,
        duration: 0.3,
        ease: 'power1.inOut',
        onComplete,
      })
    }

    const onBeforeLeave = (el: HTMLElement) => {
      gsap.set(el, {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      })
    }

    const onBeforeEnter = (el: HTMLElement) => {
      gsap.set(el, {
        xPercent: 15,
        opacity: 0,
      })
    }

    const onEnter = (el: HTMLElement, onComplete: () => void) => {
      gsap.to(el.parentElement, {
        height: el.clientHeight,
        duration: 0.3,
        ease: 'power1.inOut',
      })

      gsap.to(el, {
        xPercent: 0,
        opacity: 1,
        duration: 0.3,
        ease: 'power1.inOut',
        onComplete,
      })
    }

    const onAfterEnter = (el: HTMLElement) => {
      gsap.set(el.parentElement, {
        height: '',
      })
    }

    return {
      onBeforeLeave,
      onLeave,
      onBeforeEnter,
      onEnter,
      onAfterEnter,
    }
  },
})
