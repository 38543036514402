import { initializeApp } from 'firebase/app'
import { doc, getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBACOsKfxHSHMOAxHO0yIdgV7aVYGJkreE',
  authDomain: 'halp-festival.firebaseapp.com',
  projectId: 'halp-festival',
  storageBucket: 'halp-festival.appspot.com',
  messagingSenderId: '227662187983',
  appId: '1:227662187983:web:28cf678790870dfed496ba',
}

initializeApp(firebaseConfig)
export const db = getFirestore()
export const allDoc = doc(db, 'blurhash', 'all')
